
var _isNtix = true;

var darwin_ent_centre_web = angular.module('darwin_ent_centre_web', ['partials', 'ui.router', 'ngResource', 'ui.bootstrap', 'slickCarousel', 'ngCookies', '720kb.socialshare']);

darwin_ent_centre_web.config(['$locationProvider', '$resourceProvider', function($location, $resourceProvider) {
    // for use with gooo search
    $location.hashPrefix('!');
    $resourceProvider.defaults.stripTrailingSlashes = false;
}]);

darwin_ent_centre_web.value('bslSettings', (function() {
  var YourcentreSettings = {
         rebase: function(url) {
                      this.bslMediaUrl = 'https://bsl-media1.backstagelive.co/'

//            this.bslMediaUrl = 'http://' + url + '/media/'
            // note: V2 url does not include project - its part of the appended uri
            this.bslApiV2Url = 'https://' + url + '/api_v2/'

            // V1 urls include project
            this.bslApiUrl = 'https://' + url + '/dec/api/v1/'
            this.bslApiSecondaryUrl = 'https://' + url + '/ntix/api/v1/'
            this.bslApiUrlOffsite = 'https://' + url + '/dec_offsite/api/v1/'
            this.bslApiUrlDarwinFestival = 'https://' + url + '/dec_darwin_festival/api/v1/'
         },
  };

  var NtixSettings = {
         rebase: function(url) {
            this.bslMediaUrl = 'https://' + url + '/media/'
            this.bslApiUrl = 'https://' + url + '/ntix/api/v1/'
            this.bslApiSecondaryUrl = 'https://' + url + '/dec/api/v1/'
            this.bslApiUrlOffsite = 'https://' + url + '/dec_offsite/api/v1/'
            this.bslApiUrlDarwinFestival = 'https://' + url + '/dec_darwin_festival/api/v1/'
         },
  };

  YourcentreSettings.rebase('backstagelive.co');
  NtixSettings.rebase('backstagelive.co');

  if (_isNtix)
    return NtixSettings;
  else
    return YourcentreSettings;    

})());

darwin_ent_centre_web.run(function ($rootScope, $location, $sce, $http, bslSettings, $timeout) {

    $rootScope.customisations = {isNtix: _isNtix};
    $rootScope.initialised = false;

    $rootScope.global = {
        search: '',
        showSearch: true,
        isLoggedIn: true
    };

    if (_isNtix)
    {
      $rootScope.customisations = {
          isNtix: _isNtix,

          logoImageSrc: "../img/ntix-logo-rev.svg",
          primaryShowDataFb: "projectData/ntix/publicReadOnly/shows",
          secondaryShowDataFb: "projectData/darwinEntertainmentCentre/publicReadOnly/shows",

          backgroundImageSrc: "",

          navBarImage: "",
          navBarBackgroundColor: "#ec7623",

          facebook: "https://www.facebook.com/NTixDarwin",
          instagram: "",
          twitter: "",

          title: "NTix"
      };
    }
    else
    {
      $rootScope.customisations = {
          isNtix: _isNtix,

          logoImageSrc: "../img/dec-logo2.svg",
          primaryShowDataFb: "projectData/darwinEntertainmentCentre/publicReadOnly/shows",
          secondaryShowDataFb: "projectData/ntix/publicReadOnly/shows",

          backgroundImageSrc: "",

          navBarImage: "../img/sun-graphic.png",
          navBarBackgroundColor: "",

          facebook: "https://www.facebook.com/darwinentertainmentcentre/",
          instagram: "https://www.instagram.com/darwinentertainmentcentre/",
          twitter: "https://twitter.com/darwinentcentre",

          title: "Darwin Entertainment Centre"
      };
    }



    $rootScope.$on('$stateChangeSuccess', function(){
      //console.log($location.path())
      ga('send', 'pageview', $location.path());
      // only show search on main page
      $rootScope.showSearch = ($location.path() === "/events");

      console.log()
      if ($location.path() === "/become_member/")
        window.scrollTo(0, 0);
    });

    function clientHandler(client) {
        $rootScope.global.isLogedIn = false;
        if (client != null) {            
            $rootScope.global.isLogedIn = true;
        }
    }

    // get sponsor list over V2 API
    /*$http.get(bslSettings.bslApiV2Url + 'dec/sponsors/')
      .then(function(response) {
            ga('send', 'event', 'sponsors get success: dec/sponsors/');

          $rootScope.sponsors = response.data.values;
        },
        function(error) {
            ga('send', 'event', 'sponsors get FAIL: dec/sponsors/');

        })*/
    
    var project = "darwinEntertainmentCentre";
    if (_isNtix)
      project = "ntix";

    var valueRef = firebase.database().ref("projectData/"+project+"/publicReadOnly/uploads");
    valueRef.on('value', function(result) {
        var snap = result.val();
        $rootScope.sponsors = [];

        if (snap) {
          for (var idx in snap) {
            if (!('name' in snap[idx].uploadFile))
              continue;
            if (snap[idx].uploadFile.name.search('sponsors')>-1) {
              var data = {'data':{}};
              data.data.name = snap[idx].uploadFile.name;
              data.data.url = snap[idx].uploadFile.url;
              data.resource = snap[idx].uploadFile.url;

              $rootScope.sponsors.push(data);
            }
          }
          // swap the sponsors and supporters
          var b = $rootScope.sponsors[0];
          $rootScope.sponsors[0] = $rootScope.sponsors[2];
          $rootScope.sponsors[2] = b;
        }
    });

   var valueRef = firebase.database().ref("projectData/"+project+"/publicReadOnly/footerHtml");
    valueRef.on('value', function(result) {
        $rootScope.footerHtml = result.val();
        $rootScope.footerHtml.openingHours = $sce.trustAsHtml($rootScope.footerHtml.openingHours);
        $rootScope.footerHtml.boxOffice = $sce.trustAsHtml($rootScope.footerHtml.boxOffice);
        $rootScope.footerHtml.privacyPolicy = $sce.trustAsHtml($rootScope.footerHtml.privacyPolicy);
        $rootScope.footerHtml.conditionsOfEntry = $sce.trustAsHtml($rootScope.footerHtml.conditionsOfEntry);
        $rootScope.footerHtml.ticketTermsAndCond = $sce.trustAsHtml($rootScope.footerHtml.ticketTermsAndCond);
        $rootScope.footerHtml.complaintsPolicy = $sce.trustAsHtml($rootScope.footerHtml.complaintsPolicy);
    });

   var valueRef = firebase.database().ref("projectData/"+project+"/publicReadOnly/aboutHtml");
    valueRef.on('value', function(result) {
        $rootScope.aboutHtml = result.val();
        $rootScope.aboutHtml.careers = $sce.trustAsHtml($rootScope.aboutHtml.careers);
        $rootScope.aboutHtml.faq = $sce.trustAsHtml($rootScope.aboutHtml.faq);
        $rootScope.aboutHtml.hireForCoorporate = $sce.trustAsHtml($rootScope.aboutHtml.hireForCoorporate);
        $rootScope.aboutHtml.hireForLiveEvents = $sce.trustAsHtml($rootScope.aboutHtml.hireForLiveEvents);
        $rootScope.aboutHtml.ticketingService = $sce.trustAsHtml($rootScope.aboutHtml.ticketingService);
        $timeout( function(){
            $rootScope.$digest();
        }, 0 );
    });

   var valueRef = firebase.database().ref("projectData/"+project+"/publicReadOnly/htmlSnippets");
    valueRef.on('value', function(result) {
        $rootScope.htmlSnippets = result.val();
        $rootScope.htmlSnippets.noticePanel = $sce.trustAsHtml($rootScope.htmlSnippets.noticePanel);
        $timeout( function(){
            $rootScope.$digest();
        }, 0 );
    });

    /*$rootScope.faqs = {"VENUE DETAILS":[],"TICKETING INFORMATION":[],"ATTENDING THE THEATRE":[]};

    var valueRef = firebase.database().ref("projectData/darwinEntertainmentCentre/publicReadOnly/faq");
    valueRef.on('value', function(result) {
        var snap = result.val();
        $rootScope.faq = $sce.trustAsHtml(snap.data.faq);
        $rootScope.$digest();
    });*/

});

darwin_ent_centre_web.directive('ngImageOnLoad', function ($parse) {

    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {

                  scope.$apply(function (){
                      $parse(attrs.ngImageOnLoad)(scope, {'this': element[0]});
                  });

                  event.preventDefault();

            });
        }
    };
});

darwin_ent_centre_web.directive('carousel', function carousel() {
    return {
        restrict: 'A',
        replace: true,
        transclude: false,
        scope: {
            images: "="
        },
        template: '<div class="jcarousel-wrapper"><div class="jcarousel"><ul><li ng-repeat="img in images"><!--<img ng-src="{{img.imageKey}}" />-->{{img.imageKey}}</li></ul></div><a href="javascript:void(0)" class="jcarousel-control-prev">&lsaquo;</a><a href="javascript:void(0)" class="jcarousel-control-next">&rsaquo;</a></div>',
        link: function link(scope, element, attrs) {
            var container = $(element);
            var carousel = container.children('.jcarousel')

            carousel.jcarousel({
                wrap: 'circular'
            });

            scope.$watch(attrs.images, function (value) {
                carousel.jcarousel('reload');
            });

            container.children('.jcarousel-control-prev')
                .jcarouselControl({
                target: '-=1'
            });

            container.children('.jcarousel-control-next')
                .jcarouselControl({
                target: '+=1'
            });
        }
    }
});

// Extending Date object with some formatting options
(function() {
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var shortMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    Date.prototype.getMonthName = function() {
        return months[ this.getMonth()];
    };

    Date.prototype.getMonthShortName = function() {
        return shortMonths[ this.getMonth()];
    };

    Date.prototype.getDayName = function() {
        return days[ this.getDay()];
    };

    Date.prototype.get12HourTime = function() {
        // convert to 12hour time
        var hours = this.getHours();
        if (hours > 12) {
          hours -= 12;
          amPm = "pm";
        } else if (hours === 12) {
          amPm = "pm";
        } else {
          amPm = "am";
        }

        // zero pad the minutes
        var minutes = this.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }

        return [hours, ":", minutes, amPm].join('');
    };

    Date.prototype.getDateOnly = function() {
      // return a new Date object with the time stripped
      var theDate = new Date(this.getTime());
      theDate.setHours(0);
      theDate.setMinutes(0);
      theDate.setSeconds(0);

      return theDate;
    }

})();

function naiveDate(dateStr) {
  var split = dateStr.split("T");
  var date = split[0].split('-');
  var time = split[1].split(':');
  return new Date(date[0], date[1]-1, date[2], time[0], time[1]);
}

